import React from "react"
import styled from "styled-components"

import { COLORS, FONT_FAMILIES, LAYOUT } from "../constants"
type T_Types = "LIGHT" | "DARK"
type T_Alignments = "CENTER" | "LEFT"

const ALIGNMENT_MAP = {
  LEFT: "left",
  CENTER: "center",
}

interface IProps {
  type?: T_Types
  alignment?: T_Alignments
}

interface IRoot {
  type: string
  alignment: string
}

const Root = styled.div<IRoot>`
  text-align: ${({ alignment = "LEFT" }) => ALIGNMENT_MAP[alignment]};

  * {
    color: ${props => (props.type === "LIGHT" ? COLORS.WHITE : COLORS.GREY)};
  }
  strong,
  p,
  a {
    font-size: 1.4rem;
    line-height: 1.25;
    font-family: "${FONT_FAMILIES.MONTSERRAT}";
    @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
      font-size: 1.6rem;
      line-height: 1.3;
    }
  }

  p {
    margin-bottom: 30px;
  }

  p:last-child {
    margin-bottom: 0px;
  }

  a {
    text-decoration: underline;
  }

  strong {
    font-weight: 700;
  }
`

const BodyText: React.FC<IProps> = ({
  children,
  type = "LIGHT",
  alignment = "LEFT",
  ...rest
}) => {
  return (
    <Root type={type} alignment={alignment} {...rest}>
      {children}
    </Root>
  )
}

export default BodyText
