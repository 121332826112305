import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Button from "../components/Button"
import logo from "../assets/img/logo.svg"
import cross from "../assets/img/nav/cross.svg"
import burger from "../assets/img/nav/burger.svg"
import instagramIcon from "../assets/img/footer/icon-insta.svg"

import {
  COLORS,
  LINKS,
  LAYOUT,
  MAIN_NAV_ITEMS,
  FONT_FAMILIES,
} from "../constants"

const Root = styled.div`
  display: flex;
  margin: 0 auto;
  background: ${COLORS.BLUE};
  padding: 15px 25px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 0 15px 0 0;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    padding: 0 45px 0 0;
  }
`

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    justify-content: center;
    align-items: center;
  }
`

interface INavList {
  mobileNavOpen: boolean
}

const NavList = styled.ul<INavList>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: ${COLORS.BLUE};
  top: 0;
  left: 0;
  z-index: 3;
  transition: transform 0.5s;
  padding: 80px 30px;
  text-align: center;
  transform: translateX(
    ${({ mobileNavOpen }) => (mobileNavOpen ? "0" : "-100vw")}
  );

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;
    transform: unset;
    height: unset;
    width: unset;
    background: none;
    padding: unset;
    flex: 1;
  }
`

const LogoWrapper = styled.div`
  padding: 20px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin: 0;
  }
`

const Logo = styled.img`
  width: 125px;
  transform: translateX(-11px);
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    width: 100px;
    margin-right: 35px;
    transform: translateX(0);
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    width: 130px;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
    margin-right: 100px;
  }
`

const Hamburger = styled.img`
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    display: none;
  }
`

interface INavListItem {
  active?: boolean
  hasChildren?: boolean
  mobileOnly?: boolean
}

const NavListItem = styled.li<INavListItem>`
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  margin-bottom: 10px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    border-bottom: ${props =>
      props.active ? `5px solid ${COLORS.PINK}` : "5px solid transparent"};
    margin-right: 30px;
    margin-bottom: 0;
    display: ${props => props.mobileOnly && "none"};
    transition: border 0.2s;
    &:hover {
      border-bottom: 5px solid ${COLORS.PINK};
    }

    &:hover ul {
      display: block;
    }
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    margin-right: 30px;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
    margin-right: 50px;
  }

  & > a {
    display: ${props => (props.hasChildren ? "none" : "inline-flex")};

    border-bottom: ${props =>
      props.active ? `5px solid ${COLORS.PINK}` : "5px solid transparent"};
    &:hover {
      border-bottom: 5px solid ${COLORS.PINK};
    }

    @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
      border-bottom: none;
      &:hover {
        border-bottom: none;
      }
    }
  }

  & > a {
    text-decoration: none;
    font-size: 1.8rem;
    color: ${COLORS.WHITE};
    padding-top: 5px;

    @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
      display: flex;
      padding-top: 20px;
      font-size: 1.4rem;
      padding-bottom: 20px;
    }

    @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
      font-size: 1.6rem;
    }

    @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
      font-size: 1.8rem;
    }
  }
`

const SignUpListItem = styled(NavListItem)<INavListItem>`
  margin-left: auto;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-right: 20px;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    margin-left: 0;
  }
`

const DonateListItem = styled.li<INavListItem>`
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-right: 20px;
  }
`

const MobileNavClose = styled.li`
  position: absolute;
  top: 25px;
  right: 30px;
`

interface IDonateButton {
  id: string
}

const DonateButton = styled(Button)<IDonateButton>`
  display: inline-flex;
  margin-top: 15px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-top: 0;
  }
`

const SubNav = styled.ul`
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 15px 15px 0 15px;
    position: absolute;
    box-sizing: border-box;
    background-color: ${COLORS.GREY};
    min-width: 290px;
    min-height: 20px;
    margin-top: 5px;
    display: none;
  }
`

const InstagramListItem = styled.li`
  display: none;
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    display: inline;
    margin-left: auto;
    margin-right: 20px;
  }
`

const InstagramIcon = styled.img`
  width: 60px;
  margin: 0 auto;
`

const SubNavListItem = styled.li<INavListItem>`
  margin-bottom: 10px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    justify-content: flex-start;
    height: auto;
    width: 100%;
  }

  a {
    display: inline-flex;
    text-decoration: none;
    font-size: 1.8rem;
    color: ${COLORS.WHITE};
    font-family: ${FONT_FAMILIES.RFH_REGULAR};
    font-weight: normal;
    transition: border 0.2s, background 0.2s;

    border-bottom: ${props =>
      props.active ? `5px solid ${COLORS.PINK}` : "5px solid transparent"};

    &:hover {
      background-color: rgba(245, 246, 247, 0.1);
    }

    @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
      padding: 0 15px;
      border-radius: 4px;
      height: 54px;
      display: flex;
      font-size: 1.8rem;
      align-items: center;
      border-bottom: none;
      width: 100%;
      background: ${props =>
        props.active ? `rgba(245, 246, 247, 0.1)` : "transparent"};
    }

    @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
      font-size: 1.8rem;
    }
  }
`

interface INav {
  activePath?: string
  childActivePath?: string
  rest?: object
}

const Nav: React.FC<INav> = ({
  activePath = null,
  childActivePath = null,
  ...rest
}) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  return (
    <Root {...rest}>
      <Navigation>
        <Hamburger
          src={burger}
          alt={"Mobile menu"}
          onClick={() => {
            setMobileNavOpen(true)
          }}
        />

        <LogoWrapper>
          <Link to="/">
            <Logo src={logo} alt={"Logo"} />
          </Link>
        </LogoWrapper>

        <NavList mobileNavOpen={mobileNavOpen}>
          <MobileNavClose
            onClick={() => {
              setMobileNavOpen(false)
            }}
          >
            <Hamburger width={"20px"} src={cross} alt={"Close mobile menu"} />
          </MobileNavClose>

          {MAIN_NAV_ITEMS.map((item, i) => (
            <NavListItem
              onClick={() => {
                setMobileNavOpen(false)
              }}
              active={activePath === item.path}
              key={item.label}
              hasChildren={!!item.children}
            >
              {item.newTab ? (
                <a target={"_blank"} href={item.path} rel={"noopener"}>
                  {item.label}
                </a>
              ) : (
                <Link to={item.path}>{item.label}</Link>
              )}

              {item.children && (
                <SubNav>
                  {item.children.map((child, i) => (
                    <SubNavListItem
                      key={i}
                      onClick={() => {
                        if (activePath === item.path) {
                          setMobileNavOpen(false)
                        }
                      }}
                      active={child.path === childActivePath}
                    >
                      <Link to={child.path}>{child.label}</Link>
                    </SubNavListItem>
                  ))}
                </SubNav>
              )}
            </NavListItem>
          ))}

          <InstagramListItem>
            <a href={LINKS.INSTAGRAM} target={"_blank"} rel={"noopener"}>
              <InstagramIcon src={instagramIcon} alt={"Instagram"} />
            </a>
          </InstagramListItem>
          {/* <SignUpListItem active={activePath === "/sign-up"}>
            <Link to="/sign-up">Sign up</Link>
          </SignUpListItem> */}

          <DonateListItem>
            <DonateButton id={"donate-nav"} to={LINKS.DONATE} target={"_blank"}>
              Donate
            </DonateButton>
          </DonateListItem>
        </NavList>
      </Navigation>
    </Root>
  )
}

export default Nav
