interface IColors {
  WHITE: string
  BLUE: string
  PINK: string
  GREY: string
  GREEN: string
  YELLOW: string
  MUTED_GREY: string
}

interface IBreakpoints {
  MEDIUM: string
  LARGE: string
  XLARGE: string
}

interface ILayout {
  BREAKPOINTS: IBreakpoints
  MAX_CONTENT_WIDTH: string
}

interface INavItems {
  label: string
  path: string
  newTab?: boolean
  children?: INavItems[]
  id?: string
}

type IFontFamilies = Record<string, string>

type IEmailAddresses = Record<string, string>

const COLORS: IColors = {
  WHITE: "#fff",
  BLUE: "#4aa4da",
  PINK: "#cb2b50",
  GREY: "#0c212a",
  GREEN: "#4BA677",
  YELLOW: "#edab46",
  MUTED_GREY: "#f5f6f7",
}

type ILinks = Record<string, string>

const LINKS: ILinks = {
  TWITTER: "https://twitter.com/RunForHeroes",
  FACEBOOK: "https://www.facebook.com/run.for.heroes.uk/",
  INSTAGRAM: "https://www.instagram.com/run.for.heroes/",
  TIKTOK: "https://vm.tiktok.com/ZSpuEq2M/",
  STRAVA: "https://www.strava.com/clubs/runforheroes",
  SPOTIFY:
    "https://open.spotify.com/user/hg72ixmeg1n3trlv1nn7mmrik?si=aB_ZSw8SR-O6cdRd2vFjMQ",
  HELLO_FRESH: "https://www.hellofresh.co.uk/",
  ZIP_CAR: "https://www.zipcar.com/en-gb",
  CLASSPASS: "https://classpass.com/",
  LULULEMON: "https://www.lululemon.co.uk/en-gb/home",
  JUST_GIVING: "https://www.justgiving.com/",
  VITA_COCO: "https://www.vitacoco.com/",
  CORE_COLLECTIVE: "https://www.core-collective.co.uk/",
  COUCH_TO_5K:
    "https://www.nhs.uk/live-well/exercise/couch-to-5k-week-by-week/",
  DAYS: "https://daysbrewing.com/",
  DISHOOM: "https://www.dishoom.com/",
  FIIT: "https://fiit.tv/",
  RAPHA: "https://www.rapha.cc/",
  RUNNERS_NEED: "https://www.runnersneed.com/",
  MAC_ROBERTS: "https://www.macroberts.com/",
  SPORT_WALES: "https://www.sport.wales/",
  SPORT_SCOTLAND: "https://sportscotland.org.uk/",
  NHS_CHARITIES: "https://www.nhscharitiestogether.co.uk/",
  MENTAL_HEALTH_UK: "https://mentalhealth-uk.org/",
  YOUTH_SPORT_TRUST: "https://www.youthsporttrust.org/",
  BRITISH_HEART_FOUNDATION: "https://www.bhf.org.uk/",
  BOOM_CYCLE: "https://www.boomcycle.co.uk/",
  NUFFIELD_HEALTH: "https://www.nuffieldhealth.com/",
  CREATE: "https://createproductions.com/",
  RFH_MEDIUM: "https://run-for-heroes.medium.com/",
  DONATE: "https://www.justgiving.com/runforheroesuk",
  POINTS_OF_LIGHT: "https://www.pointsoflight.gov.uk/run-for-heroes/",
  QUEENS_BD_HONOURS_LIST:
    "https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/927116/Queens-Birthday-Honours-List-2020.csv/preview",
  BOLD: "https://boldstudios.ie/",
  CHOOSE_LOVE: "https://choose.love/",
  CHOOSE_LOVE_INSTAGRAM: "https://www.instagram.com/chooselove/?hl=en",
  MACMILLAN: "https://www.macmillan.org.uk/",
  GREAT_ORMOND_STREET: "https://www.gosh.nhs.uk/",
  STC: "https://www.savethechildren.org.uk/",
  ALZHEIHMERS: "https://www.alzheimersresearchuk.org/",
  COPPA_FEEL: "https://coppafeel.org/",
  LETS_DO_THIS: "https://www.letsdothis.com/gb",
  ROUTE_40: "https://route40.co/",
  FIND_A_RACE: "https://findarace.com/",
  TOOLKIT:
    "https://drive.google.com/drive/folders/1Uyu9IirF3MCoO8jTvjbmkAfiC931VgIr?usp=sharing",
  FOR_CHARITIES: "https://runforheroes.justgiving.com/for-charities",
  TALA: "https://www.wearetala.com/",
  PIP_NUT: "https://www.pipandnut.com/",
  WILD: "https://www.wearewild.com/",
  TENZING: "https://tenzingnaturalenergy.com/",
  KOBOX: "https://www.koboxboxingclub.com/",
  BMF: "https://www.bemilitaryfit.com/",
  GOODIEBAG: "https://indd.adobe.com/view/ce210b0e-b255-4ef2-ad0b-1d2782c4aee7",
  TRIBE: "https://wearetribe.co/",
  ASICS_LONDON_10K:
    "https://run.limelightsports.club/event/london-2021/asics-london-10k",
  JIMMYS: "https://www.jimmysicedcoffee.com/",
  BARRECORE: "https://www.barrecore.com/",
  PODCAST: "https://podfollow.com/run",
}

const LAYOUT: ILayout = {
  BREAKPOINTS: { MEDIUM: "768px", LARGE: "1024px", XLARGE: "1300px" },
  MAX_CONTENT_WIDTH: "1160px",
}

const MAIN_NAV_ITEMS: INavItems[] = [
  // { label: "5kMay", path: "/5k-may" },
  {
    label: "About Us",
    path: "/about-us",
    // children: [
    //   { label: "About us", path: "/about-us" },
    //   { label: "Our Charity", path: "/about-us/our-charity" },
    //   // { label: "Our Story", path: "/about-us/our-story" },
    //   // { label: "The 5k Challenge", path: "/about-us/5k-challenge" },
    // ],
  },
  { label: "Our Charity", path: "/about-us/our-charity" },
  {
    label: "Partners",
    path: "/our-partners/corporate",
    // children: [
    //   { label: "Corporate Partners", path: "/our-partners/corporate" },
    //   { label: "Charity Partners", path: "/our-partners/charity" },
    // ],
  },
  // { label: "Our Shop", path: "/our-shop" },
  {
    label: "Stories",
    path: LINKS.RFH_MEDIUM,
    newTab: true,
    children: [
      { label: "Blog", path: LINKS.RFH_MEDIUM, newTab: true },
      { label: "Podcast", path: LINKS.PODCAST, newTab: true },
    ],
  },
  // { label: "Win HelloFresh", path: "/hellofresh-competition" },
  // { label: "For Charities", path: LINKS.FOR_CHARITIES, newTab: true },
]

const FOOTER_NAV_ITEMS: INavItems[] = [
  { label: "Privacy policy", path: "/privacy-policy" },
  { label: "Contact us", path: "/contact-us" },
  { label: "About us", path: "/about-us" },
  // { label: "5k May", path: "/5k-may" },
  { label: "Our Charity", path: "/about-us/our-charity" },
  { label: "Our Corporate Partners", path: "/our-partners/corporate" },
  // { label: "Our Charity Partners", path: "/our-partners/charity" },
  // { label: "Our Shop", path: "/our-shop" },
  { label: "Our Stories", path: LINKS.RFH_MEDIUM, newTab: true },
  // { label: "Sign up", path: "/sign-up" },
  { label: "Donate", path: LINKS.DONATE, newTab: true, id: "donate-footer" },
]

const FONT_FAMILIES: IFontFamilies = {
  RFH_FINE: "run-for-heroes-fine",
  RFH_REGULAR: "run-for-heroes-regular",
  RFH_OUTLINE: "run-for-heroes-outline",
  MONTSERRAT: "montserrat",
}

const EMAIL_ADDRESSES: IEmailAddresses = {
  GENERAL: "info@runforheroes.uk",
  VOLUNTEERING_RECRUITMENT: "jobs@runforheroes.uk",
  CORPORATE_CHARITY_PARTNERSHIPS: "olivia@runforheroes.uk",
  DESIGN_MARKETING: "india@runforheroes.uk",
  PRESS_PR: "press@runforheroes.uk",
  SOCIAL_MEDIA: "rebecca@runforheroes.uk",
  PRIVACY: "privacy@runforheroes.uk",
}

export {
  COLORS,
  LAYOUT,
  MAIN_NAV_ITEMS,
  FONT_FAMILIES,
  FOOTER_NAV_ITEMS,
  LINKS,
  EMAIL_ADDRESSES,
}
