import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { COLORS, FONT_FAMILIES } from "../constants"

type TButtonColors =
  | "BLUE"
  | "WHITE"
  | "GREY"
  | "WHITE_BORDERED"
  | "TRANSPARENT"

type TCOLOR_MAPS = {
  [K in TButtonColors]?: string
}

interface IRoot {
  color: TButtonColors
}

interface IProps {
  children: JSX.Element | string
  color?: TButtonColors
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  to?: string
  href?: string
  rest?: object
  target?: string
  rel?: string
}

const BACKGROUND_COLORS_MAP: TCOLOR_MAPS = {
  WHITE: COLORS.WHITE,
  WHITE_BORDERED: COLORS.WHITE,
  GREY: COLORS.GREY,
  TRANSPARENT: "transparent",
}

const COLORS_MAP: TCOLOR_MAPS = {
  WHITE: COLORS.BLUE,
  WHITE_BORDERED: COLORS.GREY,
  GREY: COLORS.WHITE,
  TRANSPARENT: COLORS.WHITE,
}

const BORDER_MAP: TCOLOR_MAPS = {
  WHITE_BORDERED: `2px solid ${COLORS.GREY}`,
  WHITE: `2px solid transparent`,
  GREY: "2px solid transparent",
  TRANSPARENT: `2px solid ${COLORS.WHITE}`,
}

const COLORS_HOVER_MAP: TCOLOR_MAPS = {
  WHITE: "#3C83AD",
  WHITE_BORDERED: "#4AA4DA",
  GREY: COLORS.BLUE,
  TRANSPARENT: COLORS.WHITE,
}

const Root = styled(Link)<IRoot>`
  display: flex;
  align-items: center;
  padding: 0 30px;
  line-height: normal;
  min-height: 40px;
  width: auto;
  background: ${props => BACKGROUND_COLORS_MAP[props.color]};
  color: ${props => COLORS_MAP[props.color]};
  border: ${props => BORDER_MAP[props.color]};
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  font-size: 1.8rem;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: ${props => COLORS_HOVER_MAP[props.color]};
  }
`

const RootHref = styled.a<IRoot>`
  display: flex;
  align-items: center;
  padding: 0 30px;
  line-height: normal;
  min-height: 40px;
  width: auto;
  background: ${props => BACKGROUND_COLORS_MAP[props.color]};
  color: ${props => COLORS_MAP[props.color]};
  border: ${props => BORDER_MAP[props.color]};
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  font-size: 1.8rem;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: ${props => COLORS_HOVER_MAP[props.color]};
  }
`

const Button: React.FC<IProps> = ({
  children,
  color = "WHITE",
  to,
  href,
  target,
  ...rest
}) => {
  if (!to && !href) return null

  return to ? (
    <Root to={to} color={color} {...rest}>
      {children}
    </Root>
  ) : (
    <RootHref href={href} color={color} {...rest}>
      {children}
    </RootHref>
  )
}

export default Button
