import React from "react"
import styled from "styled-components"

import { COLORS, LAYOUT } from "../constants"
import handLeftImage from "../assets/img/pageSection/hand-left.png"
import handLeftImage2x from "../assets/img/pageSection/hand-left@2x.png"
import handRightImage from "../assets/img/pageSection/hand-right.png"
import handRightImage2x from "../assets/img/pageSection/hand-right@2x.png"

type PAGE_SECTION_COLORS =
  | "BLUE"
  | "WHITE"
  | "MUTED_GREY"
  | "PINK"
  | "GREY"
  | "GREEN"

interface IRoot {
  backgroundColor: PAGE_SECTION_COLORS
  hasHandsBg?: boolean
  noPadding?: boolean
}

interface IProps {
  children: JSX.Element
  backgroundColor?: PAGE_SECTION_COLORS
  rest?: object
  noPadding?: boolean
  hasHandsBg?: boolean
}

const Root = styled.div<IRoot>`
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: ${({ noPadding }) => (noPadding ? 0 : "0 30px")};
  background: ${props => COLORS[props.backgroundColor]};
  overflow: hidden;

  ${props =>
    props.hasHandsBg &&
    `@media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
      background-size: 300px;
      background-position: left center, right center;
      background-repeat: no-repeat;
      background-image: url(${handLeftImage}),url(${handRightImage}) ;
      @media (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi) { 
        background-image: url(${handLeftImage2x}),url(${handRightImage2x}) ;
      }
    }
  `}
`

const PageSection: React.FC<IProps> = ({
  children,
  backgroundColor = "BLUE",
  noPadding = false,
  hasHandsBg = false,
  ...rest
}) => {
  return (
    <Root
      hasHandsBg={hasHandsBg}
      noPadding={noPadding}
      backgroundColor={backgroundColor}
      {...rest}
    >
      {children}
    </Root>
  )
}

export default PageSection
