import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Cookies from "js-cookie"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import BodyText from "../components/BodyText"
import Button from "../components/Button"

import { COLORS, LAYOUT } from "../constants"

const Root = styled.div`
  background-color: ${COLORS.GREY};
  padding: 20px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 20px 40px;
  }
`

const WrapperInner = styled.div`
  max-width: 1440px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Copy = styled(BodyText)`
  // padding-right: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    text-align: left;
    margin-bottom: 0;
    flex-direction: row;
  }

  p {
    padding-right: 25px;

    @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
      padding-left: 15px;
      padding-right: 0;
    }
  }

  strong {
    margin-right: 10px;
  }
`

const StyledButton = styled(Button)`
  align-self: flex-start;
  flex-shrink: 0;
  width: auto;
`

interface ICookieImage {
  fixed: object
}
const CookieImage = styled(Img)<ICookieImage>`
  flex-shrink: 0;
`

interface ICookieBanner {
  rest?: object
}

const Footer: React.FC<ICookieBanner> = ({ ...rest }) => {
  const [cookiesAccepted, setCookiesAccepted] = useState(
    Cookies.get("cookiesAgreed")
  )

  const data = useStaticQuery(graphql`
    query CookieBannerQuery {
      cookieBannerImg: file(relativePath: { eq: "cookieBanner/cookie.png" }) {
        childImageSharp {
          fixed(width: 41) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  if (cookiesAccepted) return null
  return (
    <Root {...rest}>
      <WrapperInner>
        <Copy>
          <CookieImage
            alt={"Cookie"}
            fixed={data.cookieBannerImg.childImageSharp.fixed}
          />
          <p>
            We use cookies to personalise content, provide social media
            features, analyse our traffic and to make our site work. For more
            information about cookies, please see our{" "}
            <Link to={"/privacy-policy"}>cookie policy</Link>.
          </p>
        </Copy>
        <StyledButton
          to={"#"}
          onClick={e => {
            e.preventDefault()
            Cookies.set("cookiesAgreed", true)
            setCookiesAccepted(true)
          }}
        >
          I'm okay with that
        </StyledButton>
      </WrapperInner>
    </Root>
  )
}

export default Footer
