import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import {
  COLORS,
  LAYOUT,
  FOOTER_NAV_ITEMS,
  FONT_FAMILIES,
  LINKS,
} from "../constants"
import twitterIcon from "../assets/img/footer/icon-twitter.svg"
import facebookIcon from "../assets/img/footer/icon-fb.svg"
import instagramIcon from "../assets/img/footer/icon-insta.svg"
import tikTokIcon from "../assets/img/footer/icon-tik-tok.svg"
import spotifyIcon from "../assets/img/footer/icon-spotify.svg"
import stravaIcon from "../assets/img/footer/icon-strava.svg"

interface IFooterSocialItems {
  name: string
  image: string
  url: string
}

const FOOTER_SOCIAL_ITEMS: IFooterSocialItems[] = [
  {
    name: "Twitter",
    image: twitterIcon,
    url: LINKS.TWITTER,
  },
  {
    name: "Facebook",
    image: facebookIcon,
    url: LINKS.FACEBOOK,
  },
  {
    name: "Instagram",
    image: instagramIcon,
    url: LINKS.INSTAGRAM,
  },
  {
    name: "TikTok",
    image: tikTokIcon,
    url: LINKS.TIKTOK,
  },
  {
    name: "Spotify",
    image: spotifyIcon,
    url: LINKS.SPOTIFY,
  },
  { name: "Strava", image: stravaIcon, url: LINKS.STRAVA },
]

const Root = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 50px 0;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1400px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0;
  }
`

const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 25px;
  }
`

const LegalRow = styled.p`
  font-family: ${FONT_FAMILIES.MONTSERRAT};
  color: ${COLORS.WHITE};
  font-size: 1.4rem;
  display: flex;
  margin-bottom: 5px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    font-size: 1.6rem;
  }
`
const Nav = styled.nav`
  display: flex;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    justify-content: flex-end;
    z-index: 1;
    position: relative;
  }
`

const NavListItem = styled.li`
  font-size: 1.4rem;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    font-size: 1.4rem;
    text-align: right;
  }
  a {
    display: inline-block;
    font-family: ${FONT_FAMILIES.MONTSERRAT};
    color: ${COLORS.MUTED_GREY};
    margin-bottom: 10px;
  }
`

const NavList = styled.ul`
  a {
    text-decoration: none;
  }
`

const SocialContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 12px;
  }
`
const SocialIcon = styled.img`
  width: 51px;
  margin-right: 15px;
`
const LeftCol = styled.div``

interface IAndreImg {
  fluid: object
}
const AndreImg = styled(Img)<IAndreImg>`
  width: 64px;
  align-self: center;
`

const BuiltByContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`

const BuiltByLinksContainer = styled.div`
  margin-left: 18px;
`

const BuiltByLink = styled.p`
  font-size: 1.4rem;
  width: 100%;
  color: ${COLORS.WHITE};
  a {
    color: ${COLORS.WHITE};
  }
`

interface IFooter {
  rest?: object
}

const Footer: React.FC<IFooter> = ({ ...rest }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      andre: file(relativePath: { eq: "footer/andre.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <LeftCol>
        <LegalContainer>
          <LegalRow>Run For Heroes Ltd © 2021 - All Rights Reserved</LegalRow>
          <LegalRow>
            We’re a registered charity in Scotland (no. 050776) and a registered
            company (no. 666385).
          </LegalRow>
        </LegalContainer>
        <SocialContainer>
          {FOOTER_SOCIAL_ITEMS.map(socialItem => (
            <a
              target={"_blank"}
              key={socialItem.name}
              href={socialItem.url}
              rel={"noopener"}
            >
              <SocialIcon alt={socialItem.name} src={socialItem.image} />
            </a>
          ))}
        </SocialContainer>
        <BuiltByContainer>
          <AndreImg fluid={data.andre.childImageSharp.fluid} />
          <BuiltByLinksContainer>
            <BuiltByLink>
              Designed by{" "}
              <a href={"https://www.ivpstudios.com/"}>IVP Studios</a>
            </BuiltByLink>
            <BuiltByLink>
              Built by <a href={"https://andrecalvo.co.uk/"}>Andre Calvo</a>
            </BuiltByLink>
          </BuiltByLinksContainer>
        </BuiltByContainer>
      </LeftCol>
      <Nav>
        <NavList>
          {FOOTER_NAV_ITEMS.map(item => (
            <NavListItem key={item.label}>
              {item.newTab ? (
                <a
                  id={item.id}
                  key={item.label}
                  href={item.path}
                  target={"_blank"}
                  rel={"noopener"}
                >
                  {item.label}
                </a>
              ) : (
                <Link key={item.label} to={item.path}>
                  {item.label}
                </Link>
              )}
            </NavListItem>
          ))}
        </NavList>
      </Nav>
    </Root>
  )
}

export default Footer
